@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  color: color("primary") !important;
  background: color("primary--muted") !important;
  text-transform: none !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: size("border-radius");

  div {
    color: color("primary") !important;
  }

  > button {
    background: transparent !important;
    border: none !important;

    > div:first-of-type {
      padding: 0 3rem;
    }
  }

  main > button {
    background: #fff;
    font-weight: normal !important;
    color: color("text--muted") !important;
    padding: size("gutter--small") size("gutter--medium") !important;
  }
}
