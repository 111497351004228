@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$filterbarHeight: size("filterbar-height");
$headerHeight: size("header-height");

:export {
  small: strip-unit(breakpoint("small"));
  filterbarHeight: strip-unit($filterbarHeight);
  headerHeight: strip-unit($headerHeight);
}

.block {

}

.productListHero {
  margin-bottom: 0;

  @media (min-width: breakpoint("tiny")) {
    margin-bottom: size("gutter--large");
  }
}
