@import "ui-components/_variables.scss";

$size: 16px;
$color: color("primary");

.block {
  user-select: none;
}

.input {
  position: absolute;
  width: $size;
  height: $size;
  opacity: 0;
}

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.button {
  display: inline-block;

  width: $size;
  height: $size;

  background: #fff;

  border-radius: 50%;

  border: 1px solid $color;

  margin-right: size("gutter--mini") / 1.5;

  [data-whatintent="keyboard"] input:focus + & {
    box-shadow: 0 0 0 .1em #7799aa;
  }

  input:active + & {
    border-color: lighten($color, 20);
  }

  .radioChecked & {
    &:after {
      content: "";
      position: relative;
      display: block;

      width:  round($size / 2);
      height: round($size / 2);

      top:  round($size / 5.7);
      left: round($size / 5.7);

      background: $color;

      border-radius: 50%;
    }
  }
}
