@import "theme/ui-components/_variables.scss";

.block {
  svg {
    width: 34px;
    height: 34px;
  }
}

.icon {
  color: color("primary");
}

.count {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: .8rem;
  color: #fff;
  z-index: 1;
  right: 9px;
  top: 13px;
  width: 16px
}
