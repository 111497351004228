@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$submitButtonHeight: 54px;
$imageWidth: 80px;

.block {
  border-radius: size("border-radius");
}

.item {
  background: #fff;
  border: 1px solid color("border--muted");
  padding: size("gutter");
  border-radius: size("border-radius");
  transition: opacity transition("duration--atoms") transition("ease-in");

  &.processing {
    opacity: 0.6;
  }

  &.disabled {
    pointer-events: none;
  }

  & + & {
    margin-top: size("gutter") / 2;
  }

  @media (min-width: breakpoint("medium")) {
    display: flex;
    justify-content: space-between;
    gap: size("gutter");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    padding-bottom: size("gutter") * 1.1;
  }
}

.left {
  position: static;

  @media (min-width: breakpoint("medium")) {
    display: flex;
    flex: 1 1 50%;
    justify-content: space-between;
  }
}

.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: size("gutter--mini");

  @media (min-width: breakpoint("medium")) {
    flex: 1 1 40%;
    text-align: right;
    padding-top: 0;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-left: 0;
  }
}

.image {
  width: $imageWidth;
  height: $imageWidth;
  border-radius: size("border-radius") / 2;
  margin-right: size("gutter--small");

  @media (max-width: (breakpoint("medium") - 1px)) {
    float: left;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: (breakpoint("medium") - 1px)) {
    position: static;
    @include clearfix();
  }
}

.name {
  display: block;
  color: color("text");
  text-transform: uppercase;
  letter-spacing: font("uc-letter-spacing");
  font-weight: bold;
  line-height: 1.2;
  margin: 0;

  @media (min-width: breakpoint("medium")) {
    width: 240px;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font--small");
  }
}

.brand {
  color: color("text--muted");
  display: inline-block;
  margin: size("gutter--small") 0;
}

.links {
  margin-top: size("gutter--small");
  text-decoration: underline;
  color: color("primary");

  @media (max-width: (breakpoint("medium") - 1px)) {
    position: absolute;
    left: size("gutter");
    bottom: size("gutter");
  }

}

.remove {
  color: color("negative") !important;
  margin-top: size("gutter") * 1.2;
}

.link {
  & + & {
    margin-left: size("gutter");
  }
}

.cash {
  display: block;
  margin-top: size("gutter--mini");
  color: color("text--muted");
  font-size: size("font--small");
}

.qtyPicker {
  @media (max-width: (breakpoint("medium") - 1px)) {
    display: flex;
    align-items: center;
    clear: both;
    width: 50%;
  }
}

.priceWrapper {
  flex: 1 0 auto;
  align-self: center;

  p {
    font-size: size("font--small");
    text-align: right;
  }

  // Points only label
  & > div > div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
  }

  .price {

    p {
      font-size: size("font");
      margin-top: 0;
      margin-bottom: 0;

      // Cash price label
      &:nth-child(2) {
        font-size: size("font--small");
      }
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
      p {
        font-size: size("font--mini") !important;
      }
    }
  }
}

.secondaryPayment {
  display: block;
  margin-top: 0;
  font-size: size("font--small");
  color: color("text--muted");

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-bottom: size("gutter") !important;
  }
}

.bundleOptions {
  margin-top: size("gutter--mini");
  font-size: size("font--small");
  color: color("text--muted");
  list-style-type: disc;
  list-style-position: inside;
}

.confirmDialogue {
  width: 500px;
  padding: size("gutter--medium");

  .closeIconWrapper {
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  button:first-child {
    color: color("text--muted");
  }

  button:last-child {
    color: color("secondary");
  }

  button {
    text-transform: uppercase;
    font-size: size("font");
  }
}

.accrualInfo {
  text-align: center;
  padding: size("gutter");
  padding-top: 0;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: color("text--muted");
    width: 20px;
    height: 20px;
  }

  span {
    margin-left: size("gutter--mini");
  }
}

.tooltip {
  text-align: left;
}

.submitButton {
  width: 100% !important;
  height: $submitButtonHeight !important;

  &.cannotAfford {
    height: auto !important;
    min-height: $submitButtonHeight !important;
    padding-top: size("gutter--mini") !important;
    padding-bottom: size("gutter--mini") !important;
  }

  &Container {
    padding: size("gutter");
  }
}
