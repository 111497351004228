@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$paddingSmall: 1.2rem;

.block {
  @include stretch($paddingSmall);

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  @media (min-width: breakpoint("small")) {
    @include stretch(size("gutter--large"));
  }
}

.item {
  width: 100%;
  float: left;
  min-width: 0;
  padding: $paddingSmall;

  @media (min-width: breakpoint("small")) {
    padding: size("gutter--large");
  }

  .block__perRow4 & {
    @media (min-width: breakpoint("tiny")) {
      width: 50%;
    }

    @media (min-width: breakpoint("small")) {
      width: 33.33%;
    }

    @media (min-width: breakpoint("medium")) {
      width: 25%;
    }
  }

  .block__perRow3 & {
    @media (min-width: breakpoint("tiny")) {
      width: 50%;
    }

    @media (min-width: breakpoint("medium")) {
      width: 33.3333%;
    }
  }

  .block__perRow2 & {
    @media (min-width: breakpoint("medium")) {
      width: 100%;
    }

    @media (min-width: breakpoint("tiny")) {
      width: 50%;
    }
  }
}

.itemInner {
  height: 100%;
}
