@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$imageWidthIncrease: 10px;

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  transition: border-color transition("duration--molecules") transition("ease-ease"),
              box-shadow transition("duration--molecules") transition("ease-ease");

  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: size("border-radius");

  &:not(.dummy):hover {
    text-decoration: none;
    border-color: color("text--muted");
    box-shadow: 0px 4px 8px 0px rgba(213,212,209,1);

    .hoverFG {
      opacity: .2;
    }

    .imageWrapper {
      transform: scale(1.01);
    }

    .name {
      text-decoration: underline;
    }
  }
}

.hoverFG {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  pointer-events: none;
  opacity: 0;
  transition: opacity transition("duration--molecules") transition("ease-ease"),
}

.header {
  overflow: hidden;
  background: color("disabled");
}

.imageWrapper {
  transition: transform transition("duration--molecules") transition("ease-ease");
}

.image {
  position: absolute;
  width: 100%;
}

.body {
  background: #fff;
  padding: size("gutter");
  text-align: left;
  // use static value to allow dummy cards
  // TODO: calculate in SASS?
  height: 145px;
  flex: 1 0 auto;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saleTag {
  height: 45px;
  width: 45px;
  color: #fff;
  background: color("secondary");
  text-align: center;
  line-height: 2.6;
  border-radius: 50%;
}

.name {
  font-weight: 700;
  color: color("text");
  font-size: size("font--medium");
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: font("line-height--heading");
  width: calc(100% - #{size("gutter") * 2});

  margin-bottom: size("gutter--mini") / 2;

  @media (min-width: breakpoint("small")) {
    width: calc(100% - #{size("gutter") * 1.5});
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font--small");
  }
}

.brand {
  margin: 0 0 size("grid-gutter") !important;
  color: color("text--muted");

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font--small");
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  margin-top: size("gutter--small");
}

.price, .msrp {
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font--small");
  }
}

.price {
  color: color("text");
  font-weight: bold;
  margin-bottom: size("gutter--tiny");
}

.msrp {
  color: color("text--muted");
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .image {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  span, p {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}

.colors {
  display: flex;
  justify-content: center;
  position: absolute;
  top: size("gutter--mini");
  z-index: 1;
  left: 0;
  right: 0;

  & > div {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    margin: 0 size("gutter--mini") / 2;
  }
}
