.heading {
  margin: 1.78rem 0;
}

.item, .summaryRow {
  display: flex;
  justify-content: space-between;
  padding: 1.13rem;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}
.item + .item, .item + .summaryRow, .summaryRow + .item, .summaryRow + .summaryRow {
  margin-top: 0.5rem;
}

.hasErrors {
  border: 1px solid #e81e14 !important;
  color: #e81e14 !important;
}

.items, .summary {
  border-radius: 6px;
}

.item {
  background: #fff;
}

.left {
  position: static;
}
@media (min-width: 1057px) {
  .left {
    display: flex;
  }
}

.right {
  display: flex;
  align-items: center;
  min-width: fit-content;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: calc(6px / 2);
}
@media (max-width: 1056px) {
  .image {
    float: left;
    margin-right: 0.95rem;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1.13rem;
  margin-right: 1.13rem;
}

.name {
  margin: 0;
}

.loading {
  padding: 1.13rem;
}

.brand {
  color: #4f595f;
  margin: 0;
}

.price {
  color: #4f595f;
  font-size: 0.88rem;
}

.summaryRow .left .info {
  margin-left: 0;
}

.link {
  display: flex;
  align-items: center;
}
.link img {
  filter: contrast(0.4);
  padding-top: 1px;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 1.13rem;
  border-radius: 6px;
}
.summaryRow .title {
  margin-bottom: 0;
  font-size: 1.25rem;
}
.summaryRow .subTitle {
  color: #4f595f;
  margin-top: 0;
}
.summaryRow .subTitle .emptyAddress {
  color: #e44200;
}
.summaryRow + .summaryRow {
  margin-top: 0.5rem;
}

.accordion {
  border-radius: 6px;
  overflow: hidden;
}
.accordionHeader {
  background: #fff;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.95rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  user-select: none;
}
.accordionHeader span {
  font-weight: normal;
  color: #4f595f;
}
.accordion .closeIcon {
  transition: transform 250ms cubic-bezier(0.4, 0, 1, 1);
  color: #1ea1ff;
  width: 24px;
  height: 24px;
}
.accordion .closeIcon.open {
  transform: scaleY(-1);
}
@media (min-width: 1057px) {
  .accordionHeader {
    display: none;
  }
  .accordion > div[data-foldable=true] {
    overflow: auto !important;
    height: auto !important;
  }
}

.otp {
  padding: 1.13rem;
}
.otp button {
  width: 100%;
}

.paymentMethods {
  padding: 1.13rem;
}
.paymentMethods h2 {
  font-size: 0.88rem;
  color: #282828;
}

@media (min-width: 802px) {
  .containerBlock {
    display: flex;
    left: -0.25rem;
    width: calc(100% + 0.5rem);
    max-width: none;
  }
  :global(.no-csscalc) .containerBlock {
    left: auto;
  }
  .containerBlock:after {
    content: "";
    clear: both;
    display: table;
  }
}
@media (min-width: 802px) {
  .containerLeft {
    padding: 0 calc(0.5rem / 2);
    float: left;
    width: 73%;
  }
}
@media (max-width: 801px) {
  .containerRight {
    margin-top: 1.78rem;
  }
}
@media (min-width: 802px) {
  .containerRight {
    padding: 0 calc(0.5rem / 2);
    float: right;
    flex: 0 0 auto;
    width: 340px;
  }
}