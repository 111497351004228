@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.serviceLink {
  &Block {
    margin-top: size("gutter--large");
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Text {
    flex: 1 0 auto;
    text-align: center;

    a {
      display: inline-block;
      text-decoration: underline;

      margin-top: size("gutter--mini");
    }
  }
}

