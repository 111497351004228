@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  transition: opacity transition("duration--atoms") transition("ease-in");
  font-size: size("font--small");

  &.processing {
    opacity: 0.6;
    pointer-events: none;
  }

  h4 {
    font-size: size("font--small");
  }
}

.header {
  @media (max-width: (breakpoint("medium") - 1px)) {
    display: none;
  }
}

.closeIcon {
  transition: transform transition("duration--organisms") transition("ease-in");

  width: 24px;
  height: 24px;

  .open & {
    transform: scaleY(-1);
  }
}

.body {
  table {
    width: 100%;
    line-height: font("line-height");
  }

  td {
    vertical-align: top;

    &:last-child {
      text-align: right;
      color: color("text--muted");

      > h4, span {
        color: color("text");
      }
    }
  }

  hr {
    border-color: color("disabled");
    margin: 0;
  }

  button {
    margin: size("gutter--large") 0;
    width: 100%;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    .foldable {
      padding: 0;
    }
  }
}

.tableSection {
  padding-top: size("gutter--mini");
}

.footer {
  padding-top: 0px;
  transition: padding-top transition("duration--organisms") transition("ease-in");

  h4 {
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: size("gutter--small") !important;
}

.continueShopping {
  display: block;
  text-align: center;
  margin: 0 0 size("gutter--small") !important;
}
