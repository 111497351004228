@import "theme/ui-components/_variables.scss";

.block {
  margin-bottom: size("gutter--large") * 2;
  position: fixed;
  z-index: z-index("system");
  top: 0;
  left: 0;
  right: 0;

  &.onCheckout {
    @media (max-width: (breakpoint("tiny-small") - 1px)) {
      position: initial;
      margin-bottom: 0;
    }
  }
}

.mobileCheckout {
  color: #fff;
  padding-top: size("gutter");

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .steps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;

    .counter {
      font-size: size("font--mini");
    }

    .title {
      margin-top: size("gutter--small") / 3;
    }
  }

  .back {
    position: absolute;
    left: 0px;
    padding: size("gutter");
    cursor: pointer;
    user-select: none;
  }

  svg {
    color: #fff;
  }

  @media (min-width: (breakpoint("medium") + 1px)) {
    display: none;
  }
}
