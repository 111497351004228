@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.container {
  &Block {
    @media (min-width: breakpoint("tiny-small")) {
      display: flex;
      @include stretch();
      @include clearfix();
    }
  }

  &Left {
    @media (min-width: breakpoint("tiny-small")) {
      padding: 0 size("grid-gutter") / 2;
      float: left;
      width: 73%;
    }
  }

  &Right {
    @media (max-width: (breakpoint("tiny-small") - 1px)) {
      margin-top: size("gutter--large");
    }

    @media (min-width: breakpoint("tiny-small")) {
      padding: 0 size("grid-gutter") / 2;
      float: right;
      flex: 0 0 auto;
      width: 280px;
    }

    @media (min-width: breakpoint("small")) {
      padding: 0 size("grid-gutter") / 2;
      float: right;
      flex: 0 0 auto;
      width: 340px;
    }
  }
}

