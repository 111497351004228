@import "ui-components/_variables.scss";

$size: 16px;
$color: #909090;
$active-color: color("secondary");

$checkbox-size: $size / 1.4;

@keyframes checkmark {
  0% { opacity: .4; transform: scale(.6) rotate(-30deg); };
  100% { opacity: 1; transform: scale(1) rotate(0deg); };
}

.block {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.input {
  position: absolute;
  width: $size;
  height: $size;
  opacity: 0;
}

.label {
  &:before, &.icon {
    content: "";
    box-sizing: inherit;
    display: block;
    width: $size;
    height: $size;
    border: 1px solid color("border");
    border-radius: 3px;
  }

  [data-whatintent="keyboard"] :not(input):not([tabindex="-1"]) .input:focus ~ & {
    &:before {
      border-color: $active-color;
    }
    text-decoration: underline;
    svg {
      color: $active-color;
    }

		.label > *:not(.children) {
      color: $active-color;
		}
  }

  [data-whatintent="keyboard"] :not(input):not([tabindex="-1"]) .input:focus ~ & > *:not(.children) {
		color: $active-color;
	}

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.children {
  margin-left: size("gutter--small");
  display: inline-block;
}

// checked component
.label > *:not(.children) {
  display: flex;
  justify-content: center;
  align-items: center;
	position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: $size;
  height: $size;

  .icon {
    animation: checkmark transition("duration--atoms") transition("ease-ease");
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
