@import "../../node_modules/@crossroads/ui-components/src/ui-components/Filterbar.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$height: 40px;

.block {
  background-color: color("body");
  padding: 0;
  display: flex;

  &__left, &__right {
    display: flex;
    align-items: center;
    height: $height;
  }

  &__left {
    flex: 1;
    justify-content: flex-end;

    @media (max-width: breakpoint("tiny") - 1px) {
      padding-right: size("gutter--small") / 2;
    }

    .item {
      width: 100%;
      height: 100%;

      > * + * {
        margin-right: 0rem;
      }

      @media (min-width: breakpoint("tiny")) {
        padding-right: 0;
        width: auto;
      }

      &:not(:first-child) {
        @media (min-width: breakpoint("tiny")) {
          padding-left: size("gutter") / 2;
        }
      }
    }
  }

  &__right {
    padding-left: size("gutter") / 2;
    display: flex;
    align-items: center;
    font-size: size("font--small");

    .item {
      flex: 1;
    }

    @media (max-width: breakpoint("tiny") - 1px) {
      flex: 1;
      padding-left: size("gutter--small") / 2;
    }
  }
}

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px !important;
}

.item {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: size("font--small");
}

.allFiltersButton {
  width: 100%;
  padding: size("gutter--mini") size("gutter");
}

.price {
  display: none;
  color: color("text--muted");

  & > span {
    white-space: nowrap;
    align-self: center;

    &:first-child {
      margin-right: size("gutter--large");
    }

    &:last-child {
      width: auto;
      margin-left: size("gutter--large");
    }
  }

  .slider {
    flex: 1;
    width: auto !important;
  }
}

.num_results {
  display: none;

  @media (min-width: breakpoint("small")) {
    display: flex;
  }
}
