@import "theme/ui-components/_variables.scss";

.block {
  display: flex;
  align-items: center;
  height: 45px;
}

.logoMain {
  width: auto;
  height: 100%;
}

.suffix {
  color: color("secondary");
  margin: 0 size("gutter--small");
}
