@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$paddingTopLarge: 250px;
$paddingTopSmall: 150px;
$maxWidthLoginForm: 425px;

:export {
  small: strip-unit(breakpoint(small));
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");

  a {
    color: var(--light);
    font-weight: bold;
    font-size: size("font--medium-small");
  }
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;
  color: var(--invertedText);
  padding-bottom: size("gutter--large");
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content: center;
  padding: size("gutter") 0;
  color: var(--invertedText),
}

