@import "theme/ui-components/_variables.scss";

$headerHeight: size("header-height");
$miniCartPrefHeight: 495px;
$miniCartWidth: 312px;
$miniHamburgerWidth: 25px;

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

@keyframes slideDown {
  0% { opacity: .7; transform: translateY(0); }
  100% { opacity: 1; transform: translateY($headerHeight); }
}

@keyframes fadeInSearch {
  0% { opacity: .7; }
  100% { opacity: 1; }
}

@keyframes fadeInBackButton {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.block {
  z-index: z-index("system") + 2;
  background: #fff;
  height: size("header-height");
  position: absolute;
  width: 100%;
  display: flex;
  transition: top transition("duration--organisms") transition("ease-ease");
  will-change: top;

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141,151,166,.16);
  }

  .hidden & {
    top: -size("header-height");
  }
}

.logo {
  @media (max-width: (breakpoint("small") - 1px)) {
    height: 40px;
  }
}

.hide {
  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }
}

.item__logo {
  padding: 0 !important;
  margin-right: size("gutter");

  @media (min-width: breakpoint("small")) {
    margin-right: size("gutter--large");
  }

  &:hover {
    text-decoration: none;
  }
}

.wrapper {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.left {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  @media (max-width: (breakpoint("small") - 1px)) {
    flex: 1 0 auto;
  }
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
}

.item__search {
  display: flex !important;
  flex: 1 0 auto;
  align-items: center;
  animation:  fadeInSearch transition("duration--organisms") transition("ease-out") forwards;
}

.search + .left {
  order: -1;
}

.mobile {
   &.search + .left + .right {
    display: none;
  }

   &.search + .left {
    display: none;
  }
}

.nav {
  display: flex;
  align-items: stretch;

  svg {
    width: 34px;
    height: 34px;
  }
}

.item__customerService {
  svg {
    height: 30px;
  }
}

.links {
  display: flex;
}

.steps {
  color: color("border");

  @media (max-width: breakpoint("tiny-small")) {
    display: none;
  }
}

.step {
  margin-left: size("gutter");
  color: rgba(255, 255, 255, 0.6);

  &.active {
    text-decoration: underline;
    color: #fff;
  }
}

.item {
  color: color("primary");
  text-decoration: none;
  cursor: pointer;
  font-size: size("font--medium-small");
  user-select: none;
  display: flex;
  align-items: center;
  height: size("header-height");
  padding: 0 0.8rem;
  transition: border-color transition("duration--atoms") transition("ease-out");

  &.active {
    font-weight: bold;
  }

  &:hover:not(.item__logo) {
    text-decoration: underline;
  }

  &:hover:not(.item__logo) {
    & > span {
      &:after {
        content: "";
        position: absolute;
        bottom: -6px;
        display: block;
        height: 2px;
        width: 100%;
        background: #fff;
      }
    }
  }

  &__cartCounter {
    transition: transform transition("duration--organisms") transition("ease-ease");
    will-change: transform;

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: (breakpoint("mini") - 1px)) {
      display: none;
    }
  }

  &__allproducts, &__categories, &__brands, &__customerService, &__search, &__points {
    @media (max-width: (breakpoint("small") - 1px)) {
      display: none !important;
    }
  }
}

.back {
  animation: fadeInBackButton transition("duration--templates") transition("ease-out") forwards;
}

.back {
  text-transform: uppercase;
  font-weight: 700;
}

.categories, .brands {
  @media (min-width: breakpoint("small")) {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    z-index: z-index("system") + 1;
    animation:  slideDown transition("duration--organisms") transition("ease-out") forwards;
  }
}

.dim {
  position: fixed;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system");
  background: #000;
  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.languageChevron {
  transform: rotate(-90deg);
  margin-left: size("gutter--mini");
}

.languageSwitcher {
  position: absolute;
  left: 0;
  overflow: hidden;
  border-bottom-left-radius: size("border-radius");
  border-bottom-right-radius: size("border-radius");
  font-size: size("font");
  min-width: 200px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.2);
}

.miniCart {
  position: absolute;
  top: size("header-height");
  right: size("gutter--large");
  left: auto;
  width: $miniCartWidth;
  z-index: z-index("overlay");
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.1);
  border-radius: size("border-radius");

  @media (max-width: breakpoint("small")) {
    right: size("gutter");
  }

  @media (max-width: breakpoint("tiny")) {
    right: size("gutter");
    left: size("gutter");
    width: auto;
  }
}

.accountBadge {
  margin-right: size("gutter--mini") / 2;
}

.hamburger {
  min-width: $miniHamburgerWidth;
  margin: 0 -0.8rem;

  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.desktopNavHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");
  border-bottom: 1px solid color("border--muted");

  h3 {
    margin-bottom: 0;
  }

  svg {
    height: 30px;
    width: 30px;
  }
}

.subNav {
  background: color("OOC--color");

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (max-width: (breakpoint("small") - 1px)) {
    min-height: 100vh;
  }

  display: none;

  @media (max-width: (("small") - 1px)) {
    left: 100%;
  }
}

.desktop {
  @media (max-width: (breakpoint("small") - 1px)) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: breakpoint("small")) {
    display: none !important;
  }
}

:not(.mobile).subNav.search {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  background: none;
  position: relative;
  top: auto; right: auto; bottom: auto; left: auto;
}

.mode__categories .categories { display: block; }
.mode__brands .brands { display: block; }
.mode__search .search { display: block; }
.mode__language .language { display: block; }