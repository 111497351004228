@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

$borderRadius: 4px;
$paddingX: 1rem;
$paddingTop: 1.12rem;
$floatLabelOffset: -.7rem;
$floatLabelSize: size("font--mini");
$inputHeight: 3.8rem;

.row {
  @media (min-width: breakpoint("small")) {
    margin-bottom: size("gutter--small") / 2;
    @include stretch(size("gutter--small") / 4);
    @include clearfix();
  }
}

.s100, .s50, .s33 {
  @media (min-width: breakpoint("small")) {
    padding: 0 size("gutter--small") / 4;
    float: left;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-bottom: size("gutter--mini");
  }
}

.s100 {
  @media (min-width: breakpoint("small")) {
    width: 100%;
  }
}

.s50 {
  @media (min-width: breakpoint("small")) {
    width: 50%;
  }
}

.s33 {
  @media (min-width: breakpoint("small")) {
    width: 33.333%;
  }
}

.dropdown {
  display: block;

  [disabled] {
    cursor: not-allowed;
  }

  & > button {
    border: 0;
    height: $height;
  }
}

.prefilledCountryField {
  position: relative;
  border: 1px solid color("border--disabled");
  border-radius: $borderRadius;

  input {
    padding-top: $paddingTop;
    padding-left: $paddingX;
    padding-right: $paddingX;
    height: $inputHeight;
    line-height: $inputHeight;
    cursor: not-allowed;
    user-select: none;
    width: 100%;
  }

  label {
    position: absolute;
    top: $floatLabelOffset;
    left: $paddingX;
    font-size: $floatLabelSize;
    font-weight: 400;
    height: 100%;
    color: color("text--muted");
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  &__cta {
    position: absolute;
    height: 100%;
    top: 0;
    padding-top: $paddingTop;
    right: $paddingX;
    font-size: size("font--mini");

    span {
      color: color("text--muted");
    }

    &.invalid {
      font-size: size("font--small");
    }
  }
}
