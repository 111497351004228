@import "ui-components/_variables.scss";

$dropdown-width: 350px;
$dropdown-height: 400px;

.block {
  display: inline-block;
  padding: size("gutter--small");
  cursor: pointer;
  user-select: none;
}

.title {
  display: flex;
  align-items: center;
  color: color("primary");
  padding: size("gutter--small");
  font-size: size("font--medium");
  font-weight: 600;

  p {
    margin: 0;
  }
}

.counter {
  color: color("secondary");
  margin-left: 6px;
}

.dropdown {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  position: absolute;
  left: auto;
  bottom: auto;
  top: 97%;
  width: $dropdown-width;
  height: $dropdown-height;
  overflow: auto;
  cursor: initial;

  a {
    padding: size("gutter--small");
    color: color("primary");
    text-decoration: none;
  }

  > * + * {
    border-top: 1px solid color("border--muted");
  }
}
