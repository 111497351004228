@import "../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

$floatLabelOffset: -.7rem;

.block {
  &.float {
    label {
      text-transform: none;
      color: color("text--muted") !important;
    }

    input:disabled {
      border: 1px solid color("border");
    }
  }
}

.input {
  border: 1px solid color("border--muted");
}

.error .input {
  background: #fbeeee;
}

.errors {
  position: absolute;
  top: .88rem;
  left: 0;
  right: 0;
  padding-right: size("gutter--mini");
  width: 100%;
  text-align: right;

  margin-top: 0;

  pointer-events: none;
}

.float .input:focus + .label,
.float:not(.empty) .label {
  font-weight: 400;
  font-size: size("font--mini");
  transform: translateY($floatLabelOffset);
}

