@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.optionRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: size("gutter--small");
  padding-top: size("gutter--small");
  border-top: 1px solid color("border--muted");

  &.color .optionLabel {
    display: none;
  }

  @media (max-width: breakpoint("small") - 1px) {
    align-items: stretch;
  }
}
