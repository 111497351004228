@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.cards {
  display: flex;
  justify-content: center;
  margin-top: size("gutter");
  padding: size("gutter");

  .visa path {
    fill: rgb(26, 31, 113);
  }

  &Inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -#{size("gutter--mini")} -#{size("gutter--small")};

    div {
      margin: size("gutter--mini") size("gutter--small");
      height: 24px;
    }

    svg {
      height: 100%;
      width: auto !important;
    }
  }
}
