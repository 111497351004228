@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$maxWidthLoginForm: 425px;
$containerWidth: 36rem;
$inputHeight: 44px;

.block {
  width: 100%;
  height: 100%;
  top: -15vh;
}

.wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0 size("gutter");
  max-width: $containerWidth;
  color: var(--invertedText);

  .title {
    font-size: size("font--enormous");
    font-weight: normal;
    margin-bottom: size("gutter");
  }

  .description {
    font-size: size("font--medium");
    margin-bottom: size("gutter--large");
    text-align: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input {
    height: $inputHeight;
  }

  .submit {
    width: 100%;
    margin-top: size("gutter--large");
    background: color("primary--muted") !important;
    color: color("primary") !important;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column-reverse;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: size("gutter--large");
  }
}

.login_form {
  min-width: $maxWidthLoginForm;
  @media (max-width: (breakpoint("small") - 1px)) {
    min-width: 0;
    width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding: size("gutter") 0;
  color: var(--invertedText),
}

