@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$hintRowHeight: 250px;
$rowHeight: 350px;
$brickWidth: 40px;
$brickWidthSmall: 20px;
$wrapperPadding: 1.2rem;
$wrapperPaddingLargeX: size("gutter--large");
$wrapperPaddingLargeY: size("gutter--large") * 1.2;

.block, .blockHint {
  margin-top: -#{size("header-margin")};
  color: #fff;
  background-color: color("primary");
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 20rem;
}

.innerBlock {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

.blockHint {
  min-height: $hintRowHeight;
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  padding: 0px;

  @media (min-width: breakpoint("small")) {
    flex-direction: row;
  }
}

.text {
  padding: 1rem 0 2rem;
  text-align: center;
  max-width: 35rem;
  margin: auto;

  .gotImage & {
    padding: 0;
  }

  .title {
    margin-bottom: size("gutter--large") * 1.25;
  }

  .subTitle {
    font-size: size("font--medium");
  }
}

.content {
  padding: $wrapperPaddingLargeY $wrapperPadding;
  width: 100%;

  @media (min-width: breakpoint("small")) {
    .gotImage & {
      min-height: $rowHeight;
    }
  }

  @media (min-width: breakpoint("medium")) {
    padding: $wrapperPaddingLargeY $wrapperPaddingLargeX;
  }
}

.imageContainer {
  overflow: hidden;
  height: auto;
  width: auto;

  @media (min-width: breakpoint("small")) {
    position: absolute;
    top: 0;
    right: 0px;
    width: 50%;

    .gotImage & {
      height: 100%;
    }

    .image {
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
}

.breadcrumbs {
  margin-bottom: size("gutter--large");
}

.delimeter {
  margin: 0 size("gutter--mini") / 2;
}

.logo {
  width: 98px;
  object-fit: cover;
  margin: auto;

  @media (min-width: breakpoint("small")) {
    width: 103px;
    margin: 0;
    position: absolute;
    bottom: $wrapperPaddingLargeY;
    right: $wrapperPaddingLargeX;
  }
}
